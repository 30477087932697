import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5cf59892"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  style: {
    "color": "#fff"
  }
};
var _hoisted_2 = {
  class: "subheading"
};
export function render(_ctx, _cache) {
  var _component_el_row = _resolveComponent("el-row");

  var _component_el_col = _resolveComponent("el-col");

  return _openBlock(), _createBlock(_component_el_row, {
    justify: "start",
    align: "bottom"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_col, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_row, null, {
            default: _withCtx(function () {
              return [_createElementVNode("h4", _hoisted_1, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)])];
            }),
            _: 3
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(function () {
              return [_createElementVNode("h2", _hoisted_2, [_renderSlot(_ctx.$slots, "headline", {}, undefined, true)])];
            }),
            _: 3
          })];
        }),
        _: 3
      })];
    }),
    _: 3
  });
}