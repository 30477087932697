import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "heading"
};
var _hoisted_2 = {
  key: 1,
  class: "heading"
};
var _hoisted_3 = {
  class: "line-clamped"
};
var _hoisted_4 = {
  key: 1,
  class: "line-clamped"
};
var _hoisted_5 = {
  class: "line-clamped"
};
export default {
  props: {
    header_row_style: {
      'type': Object,
      'required': false,
      'default': function _default() {
        return {
          paddingBottom: '20px'
        };
      }
    },
    show_excerpt: {
      'type': Boolean,
      'required': false,
      'default': true
    },
    show_date: {
      'type': Boolean,
      'required': false,
      'default': true
    },
    postSlug: {
      'type': String,
      'required': false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_router_link = _resolveComponent("router-link");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "start"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                align: "middle",
                justify: "start"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("h2", null, [_renderSlot(_ctx.$slots, "tagname")])];
                }),
                _: 3
              }), _createVNode(_component_el_row, {
                align: "middle",
                justify: "start",
                style: _normalizeStyle(__props.header_row_style)
              }, {
                default: _withCtx(function () {
                  return [__props.postSlug ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "font-color-white",
                    to: "/community/".concat(__props.postSlug)
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("h1", _hoisted_1, [_renderSlot(_ctx.$slots, "headertext")])];
                    }),
                    _: 3
                  }, 8, ["to"])) : (_openBlock(), _createElementBlock("h1", _hoisted_2, [_renderSlot(_ctx.$slots, "headertext")]))];
                }),
                _: 3
              }, 8, ["style"]), __props.show_excerpt ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                align: "middle",
                justify: "start",
                class: "blog-header-excerpt std-padding_line-md"
              }, {
                default: _withCtx(function () {
                  return [__props.postSlug ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "font-color-white",
                    to: "/community/".concat(__props.postSlug)
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("h3", _hoisted_3, [_renderSlot(_ctx.$slots, "excerpttext")])];
                    }),
                    _: 3
                  }, 8, ["to"])) : (_openBlock(), _createElementBlock("h3", _hoisted_4, [_renderSlot(_ctx.$slots, "excerpttext")]))];
                }),
                _: 3
              })) : _createCommentVNode("", true), __props.show_date ? (_openBlock(), _createBlock(_component_el_row, {
                key: 1,
                align: "middle",
                justify: "start"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("p", _hoisted_5, [_renderSlot(_ctx.$slots, "date")])];
                }),
                _: 3
              })) : _createCommentVNode("", true)];
            }),
            _: 3
          })];
        }),
        _: 3
      });
    };
  }
};